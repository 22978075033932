import { DialogClose, DialogContent, DialogTitle } from 'components/dialog';
import React, { FC, ReactNode } from 'react';
import { useLocalStorage } from 'react-use';
import { isRunningInBrowser } from 'utils/helpers';
import { StyledDialogOverlay } from './single-use-dialog.styles';

interface Props {
  storageKey: string;
  title?: string | ReactNode;
  label?: string;
}

/**
 * A dialog which will only render once (sets a flag in localstorage)
 */
const SingleUseDialog: FC<Props> = ({ storageKey, title, label, children }) => {
  const [showDialog, setShowDialog] = useLocalStorage(
    storageKey,
    isRunningInBrowser()
  );
  const close = () => setShowDialog(false);

  return (
    <StyledDialogOverlay isOpen={showDialog} onDismiss={close}>
      <DialogContent
        aria-label={label}
        aria-labelledby={title ? 'dialog-title' : undefined}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {children}
        <DialogClose onClick={close}>Close</DialogClose>
      </DialogContent>
    </StyledDialogOverlay>
  );
};

export default SingleUseDialog;
