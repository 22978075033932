import { DialogContent, DialogOverlay, DialogTitle } from 'components/dialog';
import { rem, size } from 'polished';
import styled from 'styled-components';

export const StyledDialogOverlay = styled(DialogOverlay)`
  ${DialogTitle} {
    display: flex;
    align-items: center;

    svg {
      display: inline-block;
      margin-right: ${rem(10)};
      ${size(rem(24))};
    }
  }

  ${DialogContent} {
    line-height: 1.4;

    > svg {
      ${size(rem(24))};

      display: block;
      margin-bottom: ${rem(20)};
    }
  }
`;
